import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field"];

  connect() {
    if (typeof google != "undefined") {
      this.initMap();
    }
  }

  initMap() {
    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget, {
      componentRestrictions: { country: ["us", "ca"] },
      fields: ["address_components", "geometry"],
      types: ["address"],
    });
    this.autocomplete.setFields([
      "address_components",
      "geometry",
      "icon",
      "name",
    ]);
    this.autocomplete.addListener(
      "place_changed",
      this.fillInAddress.bind(this)
    );
    this.autocomplete.addListener(
      "place_changed",
      this.placeChanged.bind(this)
    );
  }

  placeChanged() {
    let place = this.autocomplete.getPlace();
    debugger;
    if (!place.geometry) return;
  }

  fillInAddress() {
    const place = this.autocomplete.getPlace();
    let address1 = "";
    let postcode = "";

    for (const component of place.address_components) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case "route": {
          address1 += component.short_name;
          break;
        }

        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case "postal_code_suffix": {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }

        case "locality": {
          document.querySelector("#coming_soon_address_attributes_city").value =
            component.long_name;
          break;
        }

        case "administrative_area_level_1": {
          document.querySelector(
            "#coming_soon_address_attributes_state"
          ).value = component.short_name;
          break;
        }
      }
    }

    if (
      place.address_components.filter((component) =>
        component["types"].includes("street_number")
      ).length === 0
    ) {
      address1 = place.address_components.filter((component) =>
        component["types"].includes("neighborhood")
      )[0].long_name;
    }

    document.querySelector("#coming_soon_address_attributes_address").value =
      address1;
    document.querySelector("#coming_soon_address_attributes_zip").value =
      postcode;

    // After filling the form with address components from the Autocomplete
    // prediction, set cursor focus on the second address line to encourage
    // entry of subpremise information such as apartment, unit, or floor number.
    document.querySelector("#coming_soon_address_attributes_address2").focus();
  }
}
