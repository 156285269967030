$(document).ready(function () {
  //on any input, checkbox, or hidden field change or input field keypress, submit the form
  $("input, select, checkbox").on("change keyup", function () {
    $("#coming_soon form input[type='submit']").click();
  });

  populate_slider();

  $(".agent-select").select2();
});

function populate_slider() {
  var slider = document.getElementById("price-range");

  noUiSlider.create(slider, {
    start: [parseInt(slider.dataset.min), parseInt(slider.dataset.max)],
    step: 1,
    range: {
      min: parseInt(slider.dataset.min),
      max: parseInt(slider.dataset.max),
    },
    format: wNumb({
      decimals: 0,
      prefix: "$",
    }),
  });

  var range = document.getElementById("price-range-vals");

  slider.noUiSlider.on("update", function (values) {
    range.innerHTML = values.join(" - ");
    $("#price_cust_min").val(values[0].substring(1));
    $("#price_cust_max").val(values[1].substring(1));
    $("#coming_soon form input[type='submit']").click();
  });
}
