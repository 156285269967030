import consumer from "./consumer"

$(function () {
  consumer.subscriptions.create({
    channel: "JobWorkerChannel", job_id: $("#job-details").attr("data-job-id") 
}, {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      if (data.status.includes("Grabbed")) {
        if ($("h3").text() == 'Your Job') {
          //window.location.reload();
          alert(data.worker + " has just grabbed this job. Please refresh the page to see the new information.")
        } else {
          $("#grab_job_form").replaceWith("<p>This job was just grabbed by someone else. <a class='btn-link' href='/jobs/grab'>Grab a different job.</a></p>")
        }
      }
    }
  });
});
