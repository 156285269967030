import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "jquery";
import "popper.js";
import "bootstrap";
import "stylesheets/application";
import "@nathanvda/cocoon";
import "chartkick/chart.js";
import LocalTime from "local-time";

Rails.start();
ActiveStorage.start();
LocalTime.start();

require("packs/jquery.mask");
require("packs/jobs");
require("packs/coming_soon");
import "controllers";
import { event } from "jquery";

window.initMap = function (...args) {
  const event = new Event("google-maps-callbacks");
  event.args = args;
  window.dispatchEvent(event);
};
